<template>
    <modal
        id="qaa"
        ref="qaa"
        :title="$t('modal.qA.heading')"
        type="product"
    >
        <form
            id="rate"
            @submit.prevent="send"
        >
            <div
                class="form-group w100"
                :class="{ error: errors.question }"
            >
                <label>{{ $t('form.fields.label.message') }} - {{ input.question.length }} /
                    1000</label>
                <textarea
                    v-model="input.question"
                    :placeholder="$t('form.fields.placeholder.addMessage')"
                    class="form-control"
                    name="question"
                    rows="4"
                />
                <span
                    v-if="errors.question"
                    class="error-message"
                >{{
                    errors.question
                }}</span>
            </div>
            <caption>
                {{ $t('modal.qA.info') }}
            </caption>
            <div class="actions">
                <b-button
                    variant="primary"
                    class="stroke"
                    @click="closeModal()"
                >
                    {{ $t('modal.qA.cancelButton') }}
                </b-button>
                <b-button
                    :disabled="sending"
                    variant="primary"
                    class="fill"
                    type="submit"
                >
                    {{ $t('modal.qA.sendButton') }}
                </b-button>
            </div>
        </form>
    </modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal.vue';
    export default {
        name: 'AddQuestionModal',
        components: {
            Modal,
        },
        data() {
            return {
                input: {
                    question: '',
                    productId: '',
                },
                errors: {},
                sending: false,
            };
        },
        computed: {
            ...mapState('product', ['inputErrors', 'product']),
        },
        methods: {
            ...mapActions('product', ['sendQuestion']),
            async send() {
                this.errors = {};
                this.input.productId = this.product.id;
                this.errors = formValidations(this.input);

                if (Object.keys(this.errors).length !== 0) {
                    return;
                }

                this.sending = true;
                await this.sendQuestion(this.input);
                this.sending = false;

                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }
                this.$toast.open({
                    message: this.$t('notification.qASent'),
                    type: 'success',
                    duration: 10000,
                });

                this.closeModal();
            },
            closeModal() {
                this.$refs.qaa.closeModal();
            },
        },
    };
</script>
<style scoped lang="scss">
.stars {
  svg {
    stroke: $gray;
    &.selected {
      stroke: $secondary;
    }
  }
  @include media-breakpoint-up(md) {
    &:hover {
      > svg {
        stroke: $secondary;
        cursor: pointer;
        &:hover ~ svg {
          stroke: $gray;
        }
      }
    }
  }
}
</style>
